import { Container } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import img1 from '../../Assets/coding.png';
import img2 from "../../Assets/developing.png"
import img3 from "../../Assets/mobile development.png"
import img4 from "../../Assets/learning.png"
import img5 from "../../Assets/social-media.png"
import img6 from "../../Assets/quality-control.png"

function WhatWeDo() {
    return (
        <>
            <Container>
                <Row>
                    <Col lg={12}>
                        <h2 className="text-center text-danger m-3">WHAT WE DO?</h2>
                        {/* <h1 className="text-center my-3 fw-bolder">An Innovative Software Development Company </h1>
                        <p className=" text-justify f-18"> Franctel Network is a young organisation with an enthusiastic team that is constantly looking at ways to set a higher benchmark for themselves. We can offer a whole host of services to your requirements. If you are thinking of getting any software solutions for your products and services, we provide the complete software solution to our clients from IT consulting, software engineering, solution design, and software product development to creating custom applications. With our software development services, our development team is highly motivated to customise according to the client’s requirements. We offer cost-effective solutions to support your business to gain the highest level of profit.</p> */}
                    </Col>

                </Row>
                <Row >
                    <Col className="my-3">
                        <Card   border="light" className="shadow p-2 hoverColor">
                            <Card.Img variant="top" src={img4} style={{ width: '150px' }} className="mx-auto px-2 pt-3" alt="img"/>
                            <Card.Body>
                                <Card.Title className="text-center fw-bolder">Sports Equipment Supply</Card.Title>
                                <Card.Text className="">
                                Our mission is to provide and support all sportsmen with quality products at very reasonable rates. We prefer to consolidate requirements from teams, institutions, clubs, and associations so that they get the maximum discounts on their purchases. We have the best brands in our listings. Like in cricket, we have SG, SS, STANFORD, and BAS. We are also launching our brand for clothing, shoes and kit bags for all sports 
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col className="my-3">
                        <Card   border="light" className="shadow p-2 hoverColor">
                            <Card.Img variant="top" src={img5} style={{ width: '150px' }} className="mx-auto px-2 pt-3" alt="img"/>
                            <Card.Body>
                                <Card.Title className="text-center fw-bolder"> Backend support to sports teams</Card.Title>
                                <Card.Text className="">
                                We have a team of video and performance analysts continuously gathering data on cricket tournaments across the globe. This helps teams to work on their strategies for their forthcoming tournaments. We are also ready to support all state cricket association teams with backend support to help their all teams during the season and after the season to get ready for next season. 
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col className="my-3">
                        <Card  border="light" className="shadow p-2 hoverColor">
                            <Card.Img variant="top" src={img6} style={{ width: '150px' }} className="mx-auto px-2 pt-3" alt="img"/>
                            <Card.Body>
                                <Card.Title className="text-center fw-bolder">Training Courses</Card.Title>
                                <Card.Text className="">
                                We have expertise in training cricket coaches, umpires, video and performance analysts, online scorers, and curators. Similarly, we have international (India)footballers, Table tennis and badminton coaches and mentors to train youngsters and aspiring sportspersons. 
                                We also have expertise in multimedia online training and .NET and mobile applications.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
                <Row >

                    <Col className="my-3">
                        <Card  border="light" className="shadow p-2 hoverColor" >
                            <Card.Img variant="top" src={img1} style={{ width: '150px' }} className="mx-auto px-2 pt-3 " alt="img"/>
                            <Card.Body className="">
                                <Card.Title className="text-center fw-bolder">Software Development</Card.Title>
                                <Card.Text className="">
                             We provide solutions to increase your business profitability with user-friendly design and programming. We carry out quality testing to  maintain  software development products and services 

                                <ul>
                                    <li>Software for data management.</li>
                                    <li>Software for device control (automation)</li>
                                    <li>Industrial software development for process control.</li>
                                    <li>Customized software development</li>
                                    <li>Vision based software development.</li>
                                    </ul>
                                
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col className="my-3">
                        <Card  border="light" className="shadow p-2 hoverColor">
                            <Card.Img variant="top" src={img2} style={{ width: '150px' }} className="mx-auto px-2 pt-3" alt="img"/>
                            <Card.Body>
                                <Card.Title className="text-center fw-bolder">Web Development</Card.Title>
                                <Card.Text className="">
                                Web development can range from developing a simple single static page to following types of websites. We will make it for you with the most beautiful and user-friendly designs  at reasonable costs 
                                <ul>
                                    <li>eCommerce website.</li>
                                    <li>Business website.</li>
                                    <li>Blog website.</li>
                                    <li>Portfolio website.</li>
                                    <li>Event website.</li>
                                    <li>Personal website.</li>
                                    <li>Membership website.</li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col className="my-3">
                        <Card   border="light" className="shadow p-2 hoverColor">
                            <Card.Img variant="top" src={img3} style={{ width: '150px' }} className="mx-auto px-2 pt-3" alt="img"/>
                            <Card.Body>
                                <Card.Title className="text-center fw-bolder">Mobile Development</Card.Title>
                                <Card.Text className="">
                                We have the expertise in creating user-friendly mobile applications in Android and ios. 
 Improve Engagement, and Generate New Revenue. 
Our mobile app development services include:

<ul>
<li>  Android and ios app development</li>
<li>Progressive Web App development and Cross-platform development</li>
<li>UI/UX design services</li>
<li>Automated QA services</li>
<li>Maintenance and ongoing support</li>

</ul>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
               

            </Container>
        </>
    );
}

export default WhatWeDo;

