import React from 'react';
import { Link } from "react-router-dom";

// import Slider from '../../Components/Slider';
// import one from '../../Assets/slider/2.jpg';
// import two from '../../Assets/slider/slide1.jpg';
// import three from '../../Assets/slider/3.png';
// import CenterMode from '../../Components/CustomSlider';
// import five from '../../Assets/slider/4.png';
import WhatWeDo from '../../Components/WhatWeDo';
import OurProducts from '../../Components/OurProducts';
import CardSlider from '../../Components/CardSlider';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import bgImgHome from '../../Assets/new/a.jpg';
// import Hom1 from '../../Assets/ball.jpg'
// import Banner from '../../Components/Banner';
import halfImg from '../../Assets/new/19198999.jpg';
import Marquee from '../../Components/Marquee';
function Home() {
    // var img=[
    //     {img:one,caption:" SMART COACH VIDEO ANALYSIS SOFTWARE"},
    //     {img:two,caption:""},
    //     {img:three,caption:""},
    //     {img:five,caption:"HIGH TECH INNOVATION & DIGITAL TRANSFORMATION"}
    // ];
    return (

        <>
        <Marquee />
            {/* <Slider images={img}/> */}
            {/* <Container fluid className='homeContainer'>
                <Row className=''>

                    <Col className='homeImg1'>
                        <main >
                            <div className="">

                                    <h1>Franctel Network
                                    </h1>
                            </div>
                        </main>
                    </Col>
                </Row>
            </Container> */}
            <Container fluid>
                <Row className='p-3'>
                    <Col className='d-flex align-items-center justify-content-center ' lg={6}>
                        <div className='p-5'>
                    <div className='text-theme display-3 fw-bold '>Franctel Network
                                    </div>
                                    <h4 className=''>An Innovative Software Development Company</h4>
                                    <p className=' textJustify'>Franctel Network is a young organisation with an enthusiastic team that is constantly looking at ways to set a higher benchmark for themselves. We can offer a whole host of services to your requirements. If you are thinking of getting any software solutions for your products and services, we provide the complete software solution to our clients from IT consulting, software engineering, solution design, and software product development to creating custom applications. With our software development services, our development team is highly motivated to customise according to the client’s requirements. We offer cost-effective solutions to support your business to gain the highest level of profit.</p>
                                    <Link as={Link} to="/about" className='text-uppercase text-center p-2 bgOrange btn-orange text-white '>LEARN MORE</Link>

                                            </div>
                    </Col>
                    <Col lg={6} className=''>
                        <img src={halfImg} alt='img' className='img-fluid'/>
                    </Col>

                </Row>
            </Container>

            {/* <Container fluid className='bg-black homebox'>
                <Row className=''>
                    <Col className='p-3 homeHeading'>
                        <div className='text-white text-cente '>Franctel Network
                        </div>
                    </Col>
                    <Col className=' p-5 text-white '>
                        <Row>
                            <Col className='bgOrange border p-3 m-1 rounded text-center homeCards'>SOFTWARE DEVELOPMENT</Col>
                            <Col className='bgOrange border p-3 m-1 rounded text-center homeCards'>BACKEND SUPPORT TO STATE, NATIONAL
                                &
                                INTERNATIONAL TEAMS</Col>
                            <Col className='bgOrange border p-3 m-1 rounded text-center homeCards'>SPORTS MANAGEMENT</Col>
                        </Row>
                        <Row>
                            <Col className='bgOrange border p-3 m-1 rounded text-center homeCards' >TRAINING COURSES</Col>
                            <Col className='bgOrange  border p-3 m-1 rounded text-center homeCards'>
                                CRICKET TOURS</Col>
                            <Col className='bgOrange border p-3 m-1 rounded text-center homeCards'>About</Col>
                        </Row>
                    </Col>
                </Row>
            </Container> */}

            <WhatWeDo />
            {/* <Banner /> */}

            <OurProducts />
            <CardSlider />
            {/* <CenterMode /> */}
         
        </>
    )

}

export default Home;