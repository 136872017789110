import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import soft from '../../Assets/soft.png';
// import sdlc from '../../Assets/sdlc.jpg'
// import Vid1 from '../../Assets/Badminton.mp4'
function SoftwareDevelopment() {
    return (

        <>
            <Container fluid className=''>
                <Row className='softImg'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>SOFTWARE DEVELOPMENT</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='#' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>SOFTWARE DEVELOPMENT</a></div>
                    </Col>

                </Row>
                {/* <Col lg={12}>
                        <div className="containerImg">


                            <div className='softImg '></div>

                            <h1 className=" text-white text-uppercase fw-bold text-center centered">SOFTWARE DEVELOPMENT</h1>


                        </div>
                    </Col> */}
                <Row>
                    <Col className='p-3'>
                        <p className='f-18'> We provide solutions to increase your business profitability with user-friendly design and programming.</p>
                        <p className='f-18'> We carry out quality testing to  maintain  software development products and services</p>
                        <ul>
                            <li>Software for data management.</li>
                            <li>Software for device control (automation)</li>
                            <li>Industrial software development for process control.</li>
                            <li>Customized software development</li>
                            <li>Vision based software development.</li>
                        </ul>

                    </Col>
                    {/* <Col className='text-center '>

                        <video width="320" height="240" controls className='rounded'>
                            <source src={Vid1} type="video/mp4" />
                                <source src="movie.ogg" type="video/ogg" />
                                    Your browser does not support the video tag.
                         </video>

                            </Col> */}

                        </Row>

                    </Container>

                </>
                )

}

                export default SoftwareDevelopment;