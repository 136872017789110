import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import CardSlider from '../../Components/CardSlider';
import aboutimg from '../../Assets/about.png'

function About() {
    return (
        <>
            <Container fluid className='my-3'>
                <Row>

                    <Col lg={12}>
                        <div className="containerImg">


                            <div className='bannerImg '></div>

                            <h1 className="display-3 text-white text-uppercase fw-bold text-center centered">ABOUT US</h1>

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4 className="text-center text-danger mt-5">CREATE A SYSTEM THAT GROWS WITH YOU
                        </h4>
                        {/* <h1 className="text-center my-3 fw-bolder">About Us </h1> */}
                        <div className="m-3 p-3 f-20 textJustify">
                            <p >Franctel Network Pvt Ltd is a technology company which offers end-to-end solutions right from design, and strategy to development, implementation and support. We develop and implement the best technology to enhance the competence and performance of sports teams and players. We have provided the most cost-effective and state-of-the-art software solutions in performance analysis for one of the biggest sports bodies, The Board of Control for Cricket in India. Our CEO Stanley Saldanha and his team have the vision to create a highly integrated system in all the cricket associations in India and across the cricket world. Also, take the same vision forward for all other sports activities
                            </p>
                        </div>

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="textOnImg">
                            <div className='aboutinfoimg'>
                            </div>
                            <div className='aboutCentered'>We have already developed mobile applications along with web portals for Cricket Management System(CRICKMS). This consists of Players' & officials' online registrations, match schedules, real-time live scores, statistics, academy management, inventory management and accounting for all expenses related to any tournament. Also developed the sports video app for all sports talent search, mentoring, coaching etc. We create customised products for clients as per their requirements </div>
                        </div>
                    </Col>
                </Row>
              


                <Row className=' border d-flex align-items-center justify-content-center p-3'>
                    <Col lg={6}  className=' d-flex align-items-center justify-content-center '>
                        <div>
                        <h4 className='text-center fw-bold text-danger'>It is teamwork that makes the dream work.</h4>
                        <p className='p-3 f-20'>If you’re creative, hard-working, and are looking to make a difference, you might want to consider working with us. We’re searching for the best and brightest, the most committed, and the folks that know what it takes to produce the best results in the first time. At the end of the day, it’s all about client results. If you want to work in a collaborative environment where opportunities are offered, skills are stretched and excellence is rewarded, you might be exactly what we’re looking for.</p>
                        </div>
                    </Col>
                    <Col lg={6}  className=' d-flex align-items-center justify-content-center'>
                        <div>
                        <img src={aboutimg} className='img-fluid' alt="img"/>

                        </div>

                    </Col>

                </Row>

            </Container>

            <CardSlider />

        </>
    )

}

export default About;