import { Container } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import aboutimg from '../../Assets/about.png';
// import aboutimg from '../../Assets/about1.jpg'
import aboutimg from '../../Assets/new/Sydney1.jpg'

import img1 from '../../Assets/icon6.png'
import img2 from '../../Assets/icon4.png'

import img3 from '../../Assets/icon5.png'

function OurProducts() {
    return (
        <>
            <Container fluid className="bglight">

                <Row>
                    <Col lg={12}>
                        <h2 className="text-center text-danger mt-5">Our Products</h2>
                        {/* <h1 className="text-center my-3 fw-bolder">Our Products</h1> */}
                        <p className=" text-center">A memorable motto or phrase that's designed to serve as a permanent <br /> expression of our company's greater purpose and mission.  </p>
                    </Col>
                </Row>

                <Row className=" d-flex align-items-center justify-content-center ">
                    <Col lg={6} >
                        <Row className="p-4">
                            <Col className="text-end">
                                <img src={img1} className='img-fluid' alt="img"/>
                            </Col>
                            <Col>
                                <h6>Analyzer</h6>
                                <p>Video & Data based 
cricket match analysis  software.
Real-time reports of match videos & statistical data
</p>
                            </Col>
                        </Row>
                       <Row className="p-4">
                            <Col className="text-end">
                                <img src={img2} className='img-fluid' alt="img"/>
                            </Col>
                            <Col >
                                <h6>Smartcoach</h6>
                                <p>Video analysis software on a desktop and mobile app for improving Technique and Skills </p>
                            </Col>
                        </Row>
                        <Row className="p-4">
                            <Col className="text-end">
                                <img src={img3} className='img-fluid' alt="img"/>
                            </Col>
                            <Col>
                                <h6>CrickMS</h6>
                                <p>Cricket Management System (CricKMS) on Mobile app, both on Android and ios and also a web-based application </p>
                            </Col>
                        </Row>
                        

                    </Col>
                    <Col lg={6} >
                        <div className=" d-flex align-items-center justify-content-center p-3 ">
                        <img src={aboutimg} className='img-fluid   rounded' alt="img"/></div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default OurProducts;
