import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Card from 'react-bootstrap/Card';

function AnalyzerPlayer() {

    return (

        <>


            <Container fluid >
                <Row className='headingimg'>
                   
                    <Col lg={12}>
                        <div className="containerImg">


                            <div className='bannerImg '></div>
                            <div className="display-3 text-white text-uppercase fw-bold text-center centered">
                            <h2 >Analyzer Player</h2>

                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='bgBlack'>


                    <Col className='m-3 text-justify'>

                        <h1 className='text-yellow p-2 text-center'>Analyzer Player</h1>
                        <ul className='text-justify'>
                            <li className='text-justify'>Video play  with slow motion and frame by frame to minutely check errors.</li>
                            <li className='text-justify'>LBW strip overlay available to judge line and length for LBW decisions like ball pitched outside <br />  the leg stump,  Impact outside the off stump and also the height of the delivery after pitching.</li>
                            <li>Using a grey scale or inverted colour, to some extent ball edging the bat can be verified.</li>
                            <li>Also using a magnifier exact spot of ball impact for the bat or pad can be highlighted.</li>
                            <li> Great utility for self-analysis for umpires, Referees and players.</li>


                        </ul>
                        <p className='px-3'>Contact : +919820518487 / +91-8983222605  </p>
                        <p className='px-3'>franctel.network1@gmail.com</p>

                        <Row className=' p-4'>
                            <Col lg={6}>
                                <Card varient='dark' className='bg-dark'>
                                    <Card.Body className='text-white'>
                                        <Card.Title className='text-center p-2'>Analyzer Player</Card.Title>

                                        <Card.Text className='text-center '>
                                            <Row className='p-3'>
                                                <Col className='p-1 text-center'>
                                                    <h6 > 1 Year </h6 ><span > <i className="fa-solid fa-indian-rupee-sign"></i> 2000</span>

                                                    <div className='py-2'> <Link to='https://razorpay.com/payment-button/pl_NO1TKL2qic1p2M/view/?utm_source=payment_button&amp;utm_medium=button&amp;utm_campaign=payment_button' className='btn btn-warning mx-lg-2 ' id='formob' target="_blank"> Purchase </Link> </div>
                                                </Col>

                                            </Row>
                                        </Card.Text>

                                    </Card.Body>
                                </Card>

                            </Col>


                        </Row>
                    </Col>

                </Row>
            </ Container >


        </>
    )
}
export default AnalyzerPlayer;


