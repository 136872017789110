import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function TrainingCourses() {
    return (

        <>
            <Container fluid >
                <Row className='trainingImg'>
                <Col className='m-4'>
                <h2 className='text-white m-5 px-2 fw-bold text-center'>TRAINING COURSES</h2>

                        <div className=" text-white fw-bold text-center">
                            <h4 className="m-3">Video analysis and performance analysis</h4>
                            <a href='https://www.smartcoach.co.in/VideoAnalysisCourse' target='_blank' className='btn  btn-success m-3'>Click here</a>
                          

                            </div>
                    </Col>
                    {/* <Col lg={12}>
                        <div className="containerImg">


                            <div className='trainingImg '></div>
                                <div className=" text-white text-uppercase fw-bold text-center centered">
                            <h2>TRAINING COURSES</h2>
                            <h4 className="">Video analysis and performance analysis</h4>
                            <a href='https://www.smartcoach.co.in/VideoAnalysisCourse' target='_blank' className='btn  btn-success'>Click here</a>
                          

                            </div>
                        </div>
                    </Col> */}
                    </Row>
                    <Row>
                    <Col className='f-18'>
                        <p className='p-3'>Stanley Saldanha during his tenure as the Manager Game Development at the  Board of Control for Cricket in India(BCCI)  was in charge of the development of umpires, video and performance analysts, referees, online scorers, and women's cricket in India. Under his leadership, we will be conducting a lot of training sessions for aspiring umpires, video and performance analysts, and coaches with the support of the best trainers</p>
                    </Col>
                </Row>

            </Container>

        </>
    )

}

export default TrainingCourses;