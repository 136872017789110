import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function SportsTours() {
    return (

        <>
            <Container fluid className='my-3'>
                <Row>

                    <Col lg={12}>
                        <div className="containerImg">


                            <div className='bannerImg '></div>
                                <div className="display-3 text-white text-uppercase fw-bold text-center centered">
                            <h1 >CRICKET TOURS</h1>
                            <h3>Coming Soon...</h3>
                            </div>
                        </div>
                    </Col>
                    <Col className='f-18'>
                        <p className='fs-5 p-3 textJustify'>Enjoy and experience cricket tours to international destinations

                            Our company specialises in meticulously designed and highly customised international sports tours for students of progressive and innovative schools, sports academies and clubs. We organise cricket  tours to the UK, South Africa, Dubai, Singapore, Malaysia, Sri Lanka

                            We ensure that all our tours are cost-effective, safe and secure.

                            Safety during international travel is the utmost priority. All our tours are a great learning experience for all participants and value for money spent. We publish our itinerary well in advance for the benefit of teams willing to join us </p>
                    </Col>
                </Row>

            </Container>

        </>
    )

}

export default SportsTours;