import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function SportsSupply() {
    return(

<>
            <Container fluid className='my-3'>
                <Row>

                    <Col lg={12}>
                        <div className="containerImg">


                            <div className='bannerImg '></div>

                            <h4 className="display-5 text-white text-uppercase fw-bold text-center centered">SPORTS EQUIPMENT SUPPLY</h4>

                        </div>
                    </Col>
                </Row>
                
                </Container>

</>
    )
    
    }
    
    export default SportsSupply;