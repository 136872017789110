import {Nav, Navbar, Container} from 'react-bootstrap';  
import { Link } from "react-router-dom";
// import NavDropdown from 'react-bootstrap/NavDropdown';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import NavDropdown from 'react-bootstrap/NavDropdown';

function Header() {  
  return (  
    <>
    <div className='bgOrange'>
    <Container>
    <Row >
      <Col className='m-2'>
 
      <span className='mx-1'><i className="fa-solid fa-envelope text-white"></i><a href="mailto:franctel.network1@gmail.com" className='text-white text-decoration-none'> franctelnetwork1@gmail.com</a></span>
      <span className='mx-1 d-block d-sm-inline-block'><i className="fa-solid fa-mobile text-white"></i><a href="tel:9820518487" className='  text-white text-decoration-none'> +91 98205 18487</a></span>
      </Col>
      <Col className='m-2'>
      <div className='d-flex justify-content-between justify-content-sm-end'>
              <a href="#" target="_blank"><i className="fa-brands fa-youtube mx-2 text-white"></i></a>
              <a href="#" target="_blank"><i className="fa-brands fa-instagram mx-2 text-white"></i></a>
              <a href="#" target="_blank"><i className="fa-brands fa-facebook mx-2 text-white"></i></a>
              <a href="https://in.linkedin.com/company/franctel-network-private-limited" target="_blank"><i className="fa-brands fa-linkedin mx-2 text-white"></i></a>
              <a href="#" target="_blank"><i className="fa-brands fa-whatsapp mx-2 text-white"></i></a>
            </div>

      </Col>
    </Row>
    </Container>
    </div>
    <Navbar bg="light" expand="xl"  className='navbar-light sticky-top'>  
    <Container fluid="sm">  
      <Navbar.Brand href="#home" className='text-dark fw-bold fs-3'>Franctel Network</Navbar.Brand>  
      <Navbar.Toggle aria-controls="basic-navbar-nav" className='text-dark'/>  
      <Navbar.Collapse id="basic-navbar-nav"  className="justify-content-end">  
        <Nav>  
          <Nav.Link as={Link} to="/" className='text-dark text-uppercase text-center mx-2 annimatedBorder'>Home</Nav.Link>  
          {/* <Nav.Link as={Link} to="/services" className='text-dark text-uppercase text-center mx-2'>Services</Nav.Link>  */}
          <Nav.Link as={Link} to="/about" className='text-dark text-uppercase text-center mx-2 annimatedBorder'>About</Nav.Link>
          <NavDropdown title="Services" id="basic-nav-dropdown" className='text-dark text-uppercase text-center mx-2 annimatedBorder'>
              <NavDropdown.Item as={Link} to="/SoftwareDevelopment" >Software Development</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/BackendSupport">Backend Support To State <br/>T-20 teams across nations</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/SportsTournament">Sports  Management</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/TrainingCourses">Training Courses</NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to="/SportsSupply">Sports Equipment Supply</NavDropdown.Item> */}
              <NavDropdown.Item as={Link} to="/SportsTours">Sports Tours</NavDropdown.Item>

            </NavDropdown>

          {/* <Nav.Link as={Link} to="/products" className='text-dark text-uppercase text-center  mx-2'>Products</Nav.Link>    */}

          <NavDropdown title="Products" id="basic-nav-dropdown" className='text-dark text-uppercase text-center mx-2 annimatedBorder'>
              <NavDropdown.Item as={Link} to="/Analyzer">Analyzer</NavDropdown.Item>
              
              <NavDropdown.Item as={Link} to="/AnalyzerPlayer">Analyzer Player</NavDropdown.Item>

              <NavDropdown.Item href="https://www.smartcoach.co.in/" target='-blank'>Smart Coach</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/CrickMS">crickMS</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/WebDevelopment">Web Development</NavDropdown.Item>

              {/* <NavDropdown.Item as={Link} to="/AnalyzerMediaPlayer">Analyzer Media Player</NavDropdown.Item> */}
              {/* <NavDropdown.Item href="/SportsApp">Sports App</NavDropdown.Item> */}
              {/* <NavDropdown.Item as={Link} to="/AssociationSpecificApp">Association Specific App</NavDropdown.Item> */}
              {/* <NavDropdown.Item as={Link} to="/DigitalScreenApp">Digital Screen App</NavDropdown.Item> */}

            </NavDropdown>
          <Nav.Link as={Link} to="/blog" className='text-dark text-uppercase text-center  mx-2 annimatedBorder'>Blogs & News</Nav.Link>
          {/* <Nav.Link as={Link} to="/store" className='text-dark text-uppercase text-center  mx-2 annimatedBorder'>Store</Nav.Link> */}

          <Nav.Link as={Link} to="/contact" className='text-dark  text-uppercase text-center mx-2 px-3 mb-1 bgOrange btn-orange text-white '>Contact</Nav.Link>

          
        </Nav>  
      </Navbar.Collapse>  
    </Container>  
  </Navbar>
  </>  
  );  
}  
export default Header;
