import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function Analyzer() {
    return (

        <>
            <Container fluid className='my-3'>
                <Row>

                    <Col lg={12}>
                        <div className="containerImg">


                            <div className='bannerImg '></div>
                            <div className="display-3 text-white text-uppercase fw-bold text-center centered">
                            <h2 >Analyzer</h2>
                            <h3 className='text-center p-3'>CRICKET MATCH ANALYSIS SOFTWARE </h3>
                            <h4>For inquiry <a href='https://docs.google.com/forms/d/e/1FAIpQLSfkQpt_w3QTLw-rhfGSE5JVJYdiE37mXl3Wap0N6-vwNw42WQ/viewform?usp=sf_link' target="_blank" className='btn btn-success'>Click here</a></h4>

                            </div>
                        </div>
                    </Col>
                    <Col>
                        {/* <h2 className='text-center p-3'>STATE OF THE ART CRICKET MATCH ANALYSIS SOFTWARE </h2> */}
                        <h3 className='text-center text-danger p-3'>Developed by a cricketer for the cricketers</h3>
                       
                        <p className='f-18'>The Analyzer has been developed with the latest Microsoft technology. The latest video-capturing software is used to reproduce quality videos. It has an amazing video-capturing process with new ideas. Where ball by ball tagging results in high-quality reports for players and teams in different formats of the games. The reports are generated for single-match and multi-match scenarios. Each data of the report has a video backup. The key performance   indicator (KPI) reports for players  and teams are integrated in such a way that the coaches, and selectors arrive at certain decision making concerning the performance analysis of any player instantly, It is of great value for team strategies, performance improvements of players and support to selectors building the right combinations for the team 
                        </p>
                        <p className='f-18'>Contact us at Franctel.network1@gmail.com to share with you the complete detail about the features and benefits of Analyzer a match-winning software.</p>
                        
                       


                    </Col>
                </Row>
            </Container>

        </>
    )

}

export default Analyzer;