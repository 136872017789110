import { Container } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Ipl from '../../Assets/ipl.pdf';
function Blog() {
    return(
        
        <Container fluid className='my-3'>
        <Row>

            <Col lg={12}>
                <div className="containerImg">


                    <div className='bannerImg '></div>

                    <h1 className="display-3 text-white text-uppercase fw-bold text-center centered">Blogs & News</h1>

                </div>
            </Col>
            <Col className="p-3">
                <p>IPL Schedule pdf file available here</p>
            <a href={Ipl} className='text-uppercase text-center p-2 bgOrange btn-orange text-white ' target="_blank">IPL Schedule</a>

            </Col>
        </Row>
        </Container>
    )
    
    }
    
    export default Blog;