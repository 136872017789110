import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function SportsTournament() {
    return (

        <>
            <Container fluid className='my-3'>
                <Row>

                    <Col lg={12}>
                        <div className="containerImg">


                            <div className='bannerImg '></div>

                            <h1 className="display-4 text-white text-uppercase fw-bold text-center centered">SPORTS MANAGEMENT</h1>

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className='p-3 f-18'>
                        <p className='px-3 f-20'>We organise and manage tournaments for Cricket, Table Tennis, Football and BadmintonWe will provide a budget for the tournament, and provide grounds and venues for the tournaments. Also, qualified officials are provided with refreshments. The cash prizes as per the size of the tournaments are at stake. We provide enough media coverage with live streaming of matches, print media coverage and also actively advertised on social media</p>
                        <h3 className='px-3'> Corporate events and tournaments</h3>
                        <p className='px-3 f-20'> Try us out for corporate events and tournaments. We assure you with the vast experience of our director Stanley Saldanha who was an ex-1st Ranji trophy cricketer and the BCCI Manager Game Development  your events will be memorable for you</p>
                    </Col>
                </Row>
            </Container>

        </>
    )

}

export default SportsTournament;