import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import RoyalLogo from '../../Assets/rlogo.jpg';
import SmartLogo from '../../Assets/slogo.PNG';
import { Link } from "react-router-dom";

function WebDevelopment() {
    return (

        <>
            <Container fluid className='my-3'>
                <Row>

                    <Col lg={12}>
                        <div className="containerImg">


                            <div className='bannerImg '></div>
                            <div className="display-3 text-white text-uppercase fw-bold text-center centered">
                            <h2 >Web Development</h2>

                            </div>
                        </div>
                    </Col>
                    <Col>
                        {/* <h2 className='text-center p-3'>STATE OF THE ART CRICKET MATCH ANALYSIS SOFTWARE </h2> */}
                                            <h3 className='text-center text-danger p-3'> Top Website Development Company in India   </h3>

                        <p className='f-18'>Franctel Network is the top Website Development Company, offering professional website design and development for small enterprises to large businesses. We offer you the best website development services for various platforms such as HTML , React Js, JavaScript , CSS, etc. Other than this, we also offer customized Web development solutions to meet your needs perfectly.As being the top website Development Company, we have a dedicated and professional expert team that creates powerful, elegant, and engaging websites.
                        </p>
                        <h3 className='text-center text-danger p-3'>Our Works</h3>

                       


                    </Col>
                </Row>
                <Row >
                    <Col>
                    <Card >
      <Card.Img variant="top" src={RoyalLogo} />
      <Card.Body>
        <Card.Title>Royal Green House</Card.Title>
        {/* <Card.Text>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </Card.Text> */}
        <a href='https://royalgreenhouse.co.in/' target='_blank' className='px-4 btn btn-outline-success'>Click to Visit</a>

      </Card.Body>
    </Card>
                    </Col>
                    <Col>
                    <Card >
      <Card.Img variant="top" src={SmartLogo} />
      <Card.Body>
        <Card.Title>Smart Coach</Card.Title>
        {/* <Card.Text>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </Card.Text> */}
        <a href='https://www.smartcoach.co.in/' target='_blank' className='px-4 btn btn-outline-success'>Click to Visit</a>

      </Card.Body>
    </Card>
                    </Col>
                </Row>
              
            </Container>

        </>
    )

}

export default WebDevelopment;