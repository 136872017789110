import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function DigitalScreenApp() {
    return(

<>
            <Container fluid className='my-3'>
                <Row>

                    <Col lg={12}>
                        <div className="containerImg">


                            <div className='bannerImg '></div>

                            <h1 className="display-3 text-white text-uppercase fw-bold text-center centered">Digital Screen App</h1>

                        </div>
                    </Col>
                </Row>
                </Container>

</>
    )
    
    }
    
    export default DigitalScreenApp;