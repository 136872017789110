import { Container } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import img1 from "../../Assets/clients/c1.png";
import img2 from "../../Assets/clients/c2.png";
import img3 from "../../Assets/clients/c3.png";
import img4 from "../../Assets/clients/c4.png";
import img5 from "../../Assets/clients/c5.png";
import img6 from "../../Assets/clients/c6.png";
import img7 from "../../Assets/clients/c7.jpg";
import img8 from "../../Assets/clients/c8.png";
import img9 from "../../Assets/clients/c9.png";
import img10 from "../../Assets/clients/c10.png";
import img11 from "../../Assets/clients/c11.png";
import img12 from "../../Assets/clients/c12.png";
import img13 from "../../Assets/clients/c13.png";
import img14 from "../../Assets/clients/c14.png";
import img15 from "../../Assets/clients/c15.png";
import img16 from "../../Assets/clients/c16.png";
import img17 from "../../Assets/clients/c17.png";

import Carousel from 'react-bootstrap/Carousel';

function CardSlider() {
    return (
        <>
        <Container>
            <Row>
        <Col>
                        <h4 className="text-center text-danger mt-5">SOME OF OUR CLIENTS
                        </h4>
                        {/* <h3 className="text-center my-3 fw-bolder">We innovate for them! We sweat for them! They love us!</h3> */}
                       
                       
                    </Col>
                    </Row>
                    </Container>

            <Carousel variant="dark" className="pb-4 m-2">
              
                <Carousel.Item className="p-lg-4">
                    <div className="px-5 mx-5 text-center">
                        <img variant="top" src={img1} style={{ width: '150px' }} className="mx-auto px-2 pt-3" alt="img"/>
                        <img variant="top" src={img2} style={{ width: '150px' }} className="mx-auto px-2 pt-3" alt="img"/>
                        <img variant="top" src={img3} style={{ width: '150px' }} className="mx-auto px-2 pt-3" alt="img"/>
                        <img variant="top" src={img4} style={{ width: '150px' }} className="mx-auto px-2 pt-3"alt="img" />
                        <img variant="top" src={img5} style={{ width: '150px' }} className="mx-auto px-2 pt-3" alt="img"/>
                        <img variant="top" src={img6} style={{ width: '150px' }} className="mx-auto px-2 pt-3" alt="img"/>
                    </div>
                </Carousel.Item>
                <Carousel.Item className="p-lg-4">
                    <div className="px-5 mx-5 text-center">
                        <img variant="top" src={img8} style={{ width: '150px' }} className="mx-auto px-2 pt-3" alt="img"/>
                        <img variant="top" src={img9} style={{ width: '150px' }} className="mx-auto px-2 pt-3" alt="img"/>
                        <img variant="top" src={img10} style={{ width: '150px' }} className="mx-auto px-2 pt-3" alt="img"/>
                        <img variant="top" src={img11} style={{ width: '150px' }} className="mx-auto px-2 pt-3" alt="img"/>
                        <img variant="top" src={img12} style={{ width: '150px' }} className="mx-auto px-2 pt-3" alt="img"/>
                        <img variant="top" src={img13} style={{ width: '150px' }} className="mx-auto px-2 pt-3" alt="img"/>
                    </div>
                </Carousel.Item>
               
                <Carousel.Item className="p-lg-4">
                    <div className="px-5 mx-5 text-center">
                        <img variant="top" src={img15} style={{ width: '150px' }} className="mx-auto px-2 pt-3" alt="img"/>
                        <img variant="top" src={img16} style={{ width: '150px' }} className="mx-auto px-2 pt-3" alt="img"/>
                        <img variant="top" src={img17} style={{ width: '150px' }} className="mx-auto px-2 pt-3" alt="img"/>
                        <img variant="top" src={img14} style={{ width: '150px' }} className="mx-auto px-2 pt-3" alt="img"/>
                        <img variant="top" src={img7} style={{ width: '150px' }} className="mx-auto px-2 pt-3" alt="img"/>
                        <img variant="top" src={img1} style={{ width: '150px' }} className="mx-auto px-2 pt-3" alt="img"/>

                    </div>
                </Carousel.Item>
            </Carousel>
        </>)

}

export default CardSlider;