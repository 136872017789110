import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function CrickMS() {
    return(

<>
            <Container fluid className='my-3'>
                <Row>

                    <Col lg={12}>
                        <div className="containerImg">


                            <div className='bannerImg '></div>
                            <div className="display-3 text-white text-uppercase fw-bold text-center centered">
                            <h2 >CrickMS</h2>
                            <h4>Cricket Management System</h4>
                            </div>
                        </div>
                    </Col>
                    <Col>
                    <h3 className='text-center'>Web-based and Mobile App</h3>
                    <p>Digitalised Integrated Cricket Management System.
Players Registration,
Officials' registration and approval by state admin.
Statistics in detail  with filters for batting, bowling and fielding etc
Tournament management,
Inventory management – Records and accounting of all cricket equipment, balls etc.
Academy management.Scoring on mobile app 
 -- display along with the full scorecard.
Interface  for LIVE score display on the association website.
Live match streaming on associations' you-tube channel</p>
                    </Col>
                </Row>
                
                </Container>

</>
    )
    
    }
    
    export default CrickMS;