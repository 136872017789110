import logo from './logo.svg';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import './App.css';
import Layout from './Pages/Layout';
import Home from "./Pages/Home";
import About from "./Pages/About";
import Blog from "./Pages/Blog";
import Contact from "./Pages/Contact";
import Products from "./Pages/Products";
import Services from "./Pages/Services";
import Store from "./Pages/Store";
import NoPage from "./Pages/NoPage";
import Terms from "././Pages/Terms";
import Privacy from "././Pages/Privacy";
import Refund from "././Pages/Refund";
import SoftwareDevelopment from './Pages/SoftwareDevelopment';
import BackendSupport from './Pages/BackendSupport'
import SportsTournament from './Pages/SportsTournament'
import TrainingCourses from './Pages/TrainingCourses';
import SportsSupply from './Pages/SportsSupply';
import SportsTours from './Pages/SportsTours';
import Analyzer from './Pages/Analyzer';
import DigitalScreenApp from './Pages/DigitalScreenApp'
import CrickMS from './Pages/CrickMS'
import AnalyzerMediaPlayer from './Pages/AnalyzerMediaPlayer';
// import SportsApp from './Pages/SportsApp'
import AssociationSpecificApp from './Pages/AssociationSpecificApp';
import WebDevelopment from './Pages/WebDevelopment';
import AnalyzerPlayer  from './Pages/AnalyzerPlayer';
function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="blog" element={<Blog />} />
        <Route path="contact" element={<Contact />} />
        <Route path="products" element={<Products />} />
        <Route path="services" element={<Services />} />
        <Route path="store" element={<Store />} />
        <Route path="terms" element={<Terms />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="refund" element={<Refund />} />
        <Route path="softwaredevelopment" element={<SoftwareDevelopment />} />
        <Route path="backendsupport" element={<BackendSupport />} />
        <Route path="sportstournament" element={<SportsTournament />} />
        <Route path="trainingcourses" element={<TrainingCourses />} />
        <Route path="sportssupply" element={<SportsSupply />} />
        <Route path="sportstours" element={<SportsTours />} />
        <Route path="analyzer" element={<Analyzer />} />
        <Route path="digitalscreenapp" element={<DigitalScreenApp />} />
        <Route path="crickms" element={<CrickMS />} />
        <Route path="analyzermediaplayer" element={<AnalyzerMediaPlayer />} />
        {/* <Route path="sportsapp" element={<SportsApp />} /> */}
        <Route path="associationspecificapp" element={<AssociationSpecificApp />} />
        <Route path="webdevelopment" element={<WebDevelopment />} />

        <Route path="analyzerplayer" element={<AnalyzerPlayer />} />

        <Route path="*" element={<NoPage />} />
      </Route>
    </Routes>
  </BrowserRouter>
  );
}

export default App;
