import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function BackendSupport() {
    return(

<>
            <Container fluid className='my-3'>
                <Row>

                    <Col lg={12}>
                        <div className="containerImg">


                            <div className='bannerImg '></div>
                            
                            <h3 className="text-white text-uppercase fw-bold text-center centered">BACKEND SUPPORT TO STATE, NATIONAL
                            <br/> &  <br/>INTERNATIONAL TEAMS</h3>
                           

                        </div>
                    </Col>
                </Row>
                <Row>

                    <Col lg={12}>
                    <h3 className="text-center my-3 fw-bolder">CRICKET ANALYTICS WITH “ ANALYZER “</h3>
                    <h3 className="text-center my-3 fw-bolder">EX-1 ST CLASS CRICKETER-OWNED COMPANY WILL GIVE YOU THE BEST SUPPORT</h3>
                    <div className="m-3 p-3 f-18">
                            <p >Having played 1st class cricket for several years and also being with BCCI as the Manger Game Development our CEO Stanley Saldanha understands the need for backend support in a much better way. Our team will provide the data-based analytical reports to National & International teams in the most specific way. 
                            </p>
                            <p > This will enable them to get ready to implement strategies for winning matches. The strengths and weaknesses of opponents as well as their team members against particular opponents can be identified in a much more organised way. We design player's profiles based on multi-match performance analysis considering all aspects the game. So that the coach and selectors are in a comfortable position to select the best team combination for team's and player's performances.</p>

                        </div>

                     
                    </Col>
                </Row>
                </Container>

</>
    )
    
    }
    
    export default BackendSupport;