import { Container } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import bat from '../../Assets/bat.jpg'
import ball from '../../Assets/ball.jpg'
import kit from '../../Assets/kit.jpg'
import bag from '../../Assets/bag.jpg'
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

function Store() {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  return (
    <>
      <Container fluid className='my-3'>
        <Row>

          <Col lg={12}>
            <div className="containerImg">


              <div className='bannerImg '></div>

              <h1 className="display-3 text-white text-uppercase fw-bold text-center centered">Store</h1>

            </div>
          </Col>
        </Row>
        <Row className="p-3">
          <Col>

            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src={bat} className="img-fluid p-3" />
              <Card.Body className="text-center">
                <Card.Title>Cricket Bat</Card.Title>
                <Card.Text>
                  ₹ 1,000
                </Card.Text>
                <span className="p-3">
                  <Button variant="primary" onClick={handleShow}>Details</Button>
                  <Modal show={show} onHide={handleClose} size="lg">
                    <Modal.Header closeButton>
                      <Modal.Title>Cricket Bat</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <img src={bat} className="p-3 img-fluid" alt="img"/>
                      About this item
                      <ul>
                        <li>Carefully selected from top of th line Kashmir willow</li>
                        <li>Light weight bat ideally suited for young aspiring cricketers</li>
                        <li>The handle is made from premium, imported sarawak cane</li>
                        <li>The best a player can get</li>
                        <li>Comes with fibre face for extra protection</li>
                      </ul>
                      ₹ 1,000
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                      <Button variant="primary">
                        Buy Now
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </span>
                <span className="p-3">
                  <Button variant="primary" >Buy Now</Button>

                </span>      </Card.Body>
            </Card>

          </Col>
          <Col>

            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src={ball} className="p-3" />
              <Card.Body className="text-center">
                <Card.Title>Cricket Ball</Card.Title>
                <Card.Text>
                  ₹ 449
                </Card.Text>
                <span className="p-3">
                  <Button variant="primary" onClick={handleShow1}>Details</Button>
                  <Modal show={show1} onHide={handleClose1} size="lg">
                    <Modal.Header closeButton>
                      <Modal.Title>Cricket Ball</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <img src={ball} className="p-3 img-fluid" alt="img"/>
                      About this item
                      <ul>
                        <li>Weight of each ball 5.5 oz - 5.75 oz , 156 gm - 163 gm</li>
                        <li>Manufactured by Kookaburra Sports India</li>
                        <li>Red Leather Ball is a high quality four-piece white cricket ball made from high quality alum tanned leather and works pretty well for both pacers and spinners.</li>
                        <li>4 Pcs. Construction</li>
                        <li>Hard Leather , Shine Polish</li>
                      </ul>
                      ₹ 449
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose1}>
                        Close
                      </Button>
                      <Button variant="primary">
                        Buy Now
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </span>
                <span className="p-3">
                  <Button variant="primary" >Buy Now</Button>

                </span>      </Card.Body>
            </Card>

          </Col>
          <Col>

            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src={kit} className="p-3" />
              <Card.Body className="text-center">
                <Card.Title>Cricket Kit</Card.Title>
                <Card.Text>
                  ₹ 749
                </Card.Text>
                <span className="p-3">
                  <Button variant="primary" onClick={handleShow2}>Details</Button>
                  <Modal show={show2} onHide={handleClose2} size="lg">
                    <Modal.Header closeButton>
                      <Modal.Title>Cricket Kit</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <img src={kit} className="p-3 img-fluid" alt="img"/><br/>
                      About this item
                      <ul>
                        <li>Perfect To Play Indoor & Outdoor Cricket: Jaspo CRIC addict cricket set is perfect choice for kids that can help to develop healthy habits. The cricket set size is perfect for little hands and convenient for the beginners kids to learn cricket.</li>
                        <li>Complete Cricket Set For Kids: Jaspo Cricket set size comes in 3 or 5 durable heavy duty plastic not damage easily. Plastic cricket set is waterproof and can be used in dripping conditions as well. Heavy base of stumps and stable which allows you to play effectively.</li>
                        <li>Complete Cricket Set For Kids: Jaspo Cricket set size comes in 3 or 5 durable heavy duty plastic not damage easily. Plastic cricket set is waterproof and can be used in dripping conditions as well. Heavy base of stumps and stable which allows you to play effectively.</li>
                       
                      </ul>
                      ₹ 749
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose2}>
                        Close
                      </Button>
                      <Button variant="primary">
                        Buy Now
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </span>
                <span className="p-3">
                  <Button variant="primary" >Buy Now</Button>

                </span>      </Card.Body>
            </Card>

          </Col>
          <Col>

            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src={bag} className="p-3" />
              <Card.Body className="text-center">
                <Card.Title>Cricket Bag</Card.Title>
                <Card.Text>
                  ₹ 1,399
                </Card.Text>
                <span className="p-3">
                  <Button variant="primary" onClick={handleShow3}>Details</Button>
                  <Modal show={show3} onHide={handleClose3} size="lg">
                    <Modal.Header closeButton>
                      <Modal.Title>Cricket Bag</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <img src={bag} className="p-3 img-fluid" alt="img"/><br/>
                      About this item
                      <ul>
                        <li>SAS international sports backpack for cricket comes with a convenient design that offers multiple compartments with adequate space. The sports kit bag has a main compartment to accommodate all gears in one place. It features an extra mini section for keeping accessories separate.</li>
                        <li>Comfortable back design with thick but soft padding, gives you maximum back support. Breathable and adjustable shoulder straps relieve the stress of the shoulder.</li>
                        <li>This bag is made up of PU Matty fabric. Made of Water Resistant and Durable material with zippers. Ensure secure & long-lasting usage everyday & weekend.</li>
                        
                      </ul>
                      ₹ 1399
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose3}>
                        Close
                      </Button>
                      <Button variant="primary">
                        Buy Now
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </span>
                <span className="p-3">
                  <Button variant="primary" >Buy Now</Button>

                </span>

              </Card.Body>
            </Card>

          </Col>
        </Row>
      </Container>


    </>)

}

export default Store;






