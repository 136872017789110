import {  Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

function Footer() {
  return (
<footer>
    <Container fluid className='  p-3 text-white'>
      <Row>
        <Col className='' xs={12}>
          <div className='p-5 text-center'>
          <h3>Franctel Network</h3>
          <p className='p-2 lightTxt'> Franctel Network is a Sports Management Company with new concepts and <br/> innovative ideas started by a first class cricketer. We blend sports experience <br/> with corporate experience for right processes, cost effective systems and <br/> result oriented goals.</p>
          <div className=''>
              <a href="#" target="_blank"><i className="fa-brands fa-youtube mx-2 text-white display-6 rotate"></i></a>
              <a href="#" target="_blank"><i className="fa-brands fa-instagram mx-2 text-white display-6 rotate"></i></a>
              <a href="#" target="_blank"><i className="fa-brands fa-facebook mx-2 text-white display-6 rotate"></i></a>
              <a href="https://in.linkedin.com/company/franctel-network-private-limited" target="_blank"><i className="fa-brands fa-linkedin mx-2 text-white display-6 rotate"></i></a>
              <a href="#" target="_blank"><i className="fa-brands fa-whatsapp mx-2 text-white display-6 rotate"></i></a>
            </div>
          </div>
          <div className='justify-content-start mx-5'>
            <div className='mx-5'>
          <h3 >Quick Links</h3>
          <ul>
            <li><Link to="/#" className='lightTxt top'>Home</Link></li>
            <li><Link to="/Terms" className='lightTxt top'>Terms & Conditions</Link></li>
            <li><Link to="/Privacy" className='lightTxt top'>Privacy Policy</Link></li>
            <li><Link to="/Refund" className='lightTxt top'>Refund Policy</Link></li>

          </ul>
          </div>


          </div>
          

        </Col>
        <hr className="hr" />
        <Col className='text-center'>
          <span className=' '>Copyright{' '}@{new Date().getFullYear()}. All rights Reserved
          </span>
        </Col>
      </Row>
    </Container>
    </footer>
  );
}
export default Footer;
