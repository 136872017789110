import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Contactimg from '../../Assets/c2.jpg'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
function Contact() {
    return (
        <>
            <Container fluid className='my-3'>
                <Row>

                    <Col lg={4}>
                        <img src={Contactimg} alt='contactimg' className='img-fluid pt-1' />
                        <Col lg={12} className='pt-1'>
                            <h3 className='mx-3 px-3'>   Our Address:-</h3>
                            <p className='mx-3 px-3 text-muted'>  402, Zara Bldg, Nahars Amrit Shakti, Powai <br />
                                Chandivali Studio, Andheri East, Mumbai, Maharashtra 400072.</p>
                        </Col>
                    </Col>

                    <Col lg={8}>
                        <Form className='m-3 bg-white rounded-4 p-3'>


                            <Row className='m-3 p-4'>
                                <h3 className='fw-bold p-1 '>
                                    Get in touch</h3>
                                <h6 className=' p-2 text-muted my-2'>
                                    Fell free to contact us and we will get back to you as soon as possible</h6>
                                <Col lg={6}>
                                    <Form.Label className=' px-1 text-muted '>First name</Form.Label>

                                    <Form.Control type="text" placeholder="John" className='p-2 m-2' />
                                </Col>
                                <Col lg={6} >
                                    <Form.Label className=' px-1 text-muted '>Last name</Form.Label>

                                    <Form.Control type="text" placeholder="Doe" className='p-2 m-2' />
                                </Col>
                                <Col lg={12}>
                                    <Form.Label className=' px-1 text-muted '>Email address</Form.Label>

                                    <Form.Control type="email" placeholder="Johndoe@example.com" className='p-2 m-2' />
                                </Col>



                                <Col lg={12}>
                                    <Form.Label className=' px-1 text-muted '>Enter Message</Form.Label>

                                    <Form.Control as="textarea" rows={5} placeholder="This is looking great and nice." className='p-2 m-2' />
                                </Col>


                                <Col lg={12} className='pt-2'>
                                    <Button variant="outline-danger" type="submit" className='p-2 m-2 mb-4 fw-bold'>
                                        Send Message
                                    </Button>
                                </Col>


                            </Row>

                        </Form>



                    </Col>
                    <Row >

                        <Col lg={12}>
                            <iframe id="gmap_canvas" height='400' width="100%" src="https://maps.google.com/maps?q=franctel%20network&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" marginheight="0" marginwidth="0"></iframe>
                            </Col>
                            {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfLLur54cyLHL8obLyXY1I1Fb4lETk0asHcxAoxrzvgL1Ywbg/viewform?embedded=true" width="640" height="674" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}
                    </Row>

                </Row>

{/* email- franctel.network.info@gmail.com
pass- franctel2022 */}
            </ Container>



        </>

    )

}

export default Contact;